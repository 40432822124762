<template>
  <NewModelLayout>
    <template #title>
      <h1 class="text-2xl">
        Change Site Settings
      </h1>
    </template>
    <template #form>
      <LocaleSwitcher
        :disable-edit-check="true"
        @switch="getData"
      />
      <vue-form
        :form="form"
        :errors.sync="error.errors"
        ref="form"
        class="mb-8"
      >
        <div class="flex flex-col space-y-4">
          <div :class="sectionClass">
            <label class="font-medium text-sm">
              {{ settings.settings_info_buckets.label }}
            </label>
            <div class="flex flex-col space-y-1">
              <div v-for="(bucket, index) in form.settings_info_buckets.value.buckets" :key="index" class="bg-gray-100 rounded px-4 py-3">
                <span class="font-medium">Bucket #{{ index + 1 }}</span>
                <vue-input-group
                  v-model="form.settings_info_buckets.value.buckets[index].title"
                  :prop="`settings_info_buckets.${index}.title`"
                  :name="`settings_info_buckets.${index}.title`"
                  label="Title"
                />
                <vue-input-group
                  v-model="form.settings_info_buckets.value.buckets[index].content"
                  :prop="`settings_info_buckets.${index}.content`"
                  :name="`settings_info_buckets.${index}.content`"
                  label="Content"
                  type="markdown"
                />
                <vue-input-group
                  v-model="form.settings_info_buckets.value.buckets[index].icon"
                  :prop="`settings_info_buckets.${index}.icon`"
                  :name="`settings_info_buckets.${index}.icon`"
                  :options="[
                    { label: 'Delivery Truck', value: 'delivery' },
                    { label: 'Shield', value: 'warranty' },
                    { label: 'United Kingdom', value: 'support' }
                  ]"
                  label="Icon"
                  labelKey="label"
                  valueKey="value"
                  type="select"
                />
                <vue-input-group
                  v-model="form.settings_info_buckets.value.buckets[index].icon_alt"
                  :prop="`settings_info_buckets.${index}.icon_alt`"
                  :name="`settings_info_buckets.${index}.icon_alt`"
                  label="Icon Alt Text"
                />
              </div>
            </div>
          </div>
          <div :class="sectionClass">
            <div class="flex items-center justify-between">
              <label class="font-medium text-sm">
                {{ settings.settings_social_media_links.label }}
              </label>
              <vue-button
                :icon="['fas', 'plus-circle']"
                dark
                @click="addSocialLink"
              />
            </div>
            <div class="flex flex-col">
              <div v-for="(link, index) in form.settings_social_media_links.value" :key="index" class="flex space-x-1 py-1">
                <vue-input
                  v-model="form.settings_social_media_links.value[index].label"
                  :prop="`settings_social_media_links.${index}.label`"
                  :name="`settings_social_media_links.${index}.label`"
                  placeholder="Label"
                  class="flex-grow"
                  prepend="Label"
                  small
                />
                <vue-input
                  v-model="form.settings_social_media_links.value[index].url"
                  :prop="`settings_social_media_links.${index}.url`"
                  :name="`settings_social_media_links.${index}.url`"
                  placeholder="URL"
                  type="url"
                  class="flex-grow"
                  prepend="URL"
                  small
                />
                <vue-input
                  v-model="form.settings_social_media_links.value[index].icon"
                  :prop="`settings_social_media_links.${index}.icon`"
                  :name="`settings_social_media_links.${index}.icon`"
                  placeholder="Icon"
                  prepend="Icon"
                  small
                />
                <vue-button
                  :icon="['fas', 'trash']"
                  error
                  outline
                  sm
                  @click="removeSocialLink(index)"
                />
              </div>
            </div>
          </div>
          <div :class="sectionClass">
            <vue-input-group
              v-model="form.settings_footer_text.value.content"
              :label="settings.settings_footer_text.label"
              :description="settings.settings_footer_text.description"
              prop="settings_footer_text"
              name="settings_footer_text"
              type="markdown"
            />
          </div>
          <div :class="sectionClass">
            <div class="flex flex-wrap w-row-offset-sm -mx-2">
              <div class="w-full space-y-2 px-2 mb-4">
                <div class="flex justify-between items-center">
                  <label v-if="settings.settings_performance_levels.label" class="font-medium text-sm">
                    {{ settings.settings_performance_levels.label }}
                  </label>
                  <vue-button
                    dark
                    class="hidden"
                    @click="addPerformanceLevel"
                  >
                    <font-awesome-icon :icon="['fas', 'plus-circle']" />
                  </vue-button>
                </div>
                <template v-if="form.settings_performance_levels">
                  <div
                    v-for="(option, index) in form.settings_performance_levels.value"
                    :key="index"
                    class="bg-gray-100 rounded px-4 py-3"
                  >
                    <div class="flex w-row-offset-sm -mx-2">
                      <vue-input-group
                        v-model="form.settings_performance_levels.value[index].min"
                        :prop="`settings_performance_levels[${index}].value[min]`"
                        :name="`settings_performance_levels[${index}].value[min]`"
                        label="Minimum Framerate"
                        type="number"
                        min="0"
                        class="w-full md:w-1/3 px-2"
                      />
                      <vue-input-group
                        v-model="form.settings_performance_levels.value[index].max"
                        :prop="`settings_performance_levels[${index}].value[max]`"
                        :name="`settings_performance_levels[${index}].value[max]`"
                        label="Maximum Framerate"
                        type="number"
                        min="0"
                        class="w-full md:w-1/3 px-2"
                      />
                      <div class="flex items-end w-full md:w-1/3 space-x-1 px-2">
                        <vue-input-group
                          v-model="form.settings_performance_levels.value[index].color"
                          :prop="`settings_performance_levels[${index}].value[color]`"
                          :name="`settings_performance_levels[${index}].value[color]`"
                          label="Background Colour"
                          class="flex-grow"
                        />
                        <div
                          :style="`background: ${form.settings_performance_levels.value[index].color}`"
                          class="group inline-flex items-center justify-center text-white w-12 h-12 rounded"
                        />
                      </div>
                    </div>
                  </div>
                </template>
                <p v-if="settings.settings_performance_levels.description" class="text-xs opacity-75 my-1">
                  {{ settings.settings_performance_levels.description }}
                </p>
              </div>
            </div>
            <vue-input-group
              v-model="form.settings_performance_minimum.value.value"
              :label="settings.settings_performance_minimum.label"
              :description="settings.settings_performance_minimum.description"
              prop="settings_performance_minimum"
              name="settings_performance_minimum"
              type="number"
              step="1"
              min="0"
              append="FPS"
            />
          </div>
          <div :class="sectionClass">
            <div class="w-full mb-4">
              <div class="flex items-center justify-between">
                <div>
                  <label class="font-medium text-sm">
                    Shipping Countries and Costs
                  </label>
                  <p class="text-xs opacity-75 my-1">
                    Specify the shipping costs and countries offered to customers to deliver to.
                  </p>
                </div>
                <vue-button
                  dark
                  @click="addCountryShipping"
                >
                  <font-awesome-icon :icon="['fas', 'plus-circle']" />
                </vue-button>
              </div>
              <div class="flex flex-col space-y-1 mt-1">
                <div
                  v-for="index in Object.keys(form.settings_shipping_countries.value.countries)"
                  :key="index"
                  class="flex items-end bg-gray-100 rounded space-x-4 px-4 pt-1 pb-3"
                >
                  <div class="flex flex-wrap w-row-offset-sm flex-grow -mx-2">
                    <vue-input-group
                      v-model="form.settings_shipping_countries.value.countries[index].code"
                      :options="countryOptions"
                      :prop="`settings_shipping_countries.countries.${index}.code`"
                      :name="`settings_shipping_countries.countries.${index}.code`"
                      label="Country"
                      type="select"
                      labelKey="label"
                      valueKey="value"
                      class="w-full md:w-1/3 px-2"
                    />
                    <vue-input-group
                      v-model="form.settings_shipping_countries.value.countries[index].base_amount"
                      :prop="`settings_shipping_countries.countries.${index}.base_amount`"
                      :name="`settings_shipping_countries.countries.${index}.base_amount`"
                      :prepend="$route.params.localeFull.currencySymbol"
                      label="Cost"
                      type="number"
                      step="0.01"
                      min="0"
                      class="w-full md:w-1/3 px-2"
                    />
                    <vue-input-group
                      v-model="form.settings_shipping_countries.value.countries[index].delivery_time"
                      :prop="`settings_shipping_countries.countries.${index}.delivery_time`"
                      :name="`settings_shipping_countries.countries.${index}.delivery_time`"
                      label="Delivery Days"
                      type="number"
                      step="1"
                      min="1"
                      append="days"
                      class="w-full md:w-1/3 px-2"
                    />
                  </div>
                  <vue-button
                    v-if="form.settings_shipping_countries.value.countries.length > 1"
                    :icon="['fas', 'trash']"
                    error
                    outline
                    sm
                    class="py-3"
                    @click="removeCountryShipping(index)"
                  />
                </div>
              </div>
            </div>
          </div>
          <div :class="sectionClass">
            <div class="w-full mb-4">
              <label class="font-medium text-sm">
                Shipping Timer Enabled
              </label>
              <vue-input-group
                v-model="form.settings_shipping_timer.value.enabled"
                label="Enabled?"
                prop="settings_shipping_timer.enabled"
                name="settings_shipping_timer.enabled"
                type="checkbox"
              />
            </div>
            <div class="w-full mb-4">
              <label class="font-medium text-sm">
                Shipping Cutoff Time
              </label>
              <div class="flex flex-wrap w-row-offset-sm -mx-2">
                <vue-input-group
                  v-model="form.settings_shipping_timer.value.deadline.hour"
                  :options="hourOptionValues"
                  label="Hour"
                  prop="settings_shipping_timer.deadline.hour"
                  name="settings_shipping_timer.deadline.hour"
                  type="select"
                  labelKey="label"
                  valueKey="value"
                  class="w-full md:w-1/2 px-2"
                />
                <vue-input-group
                  v-model="form.settings_shipping_timer.value.deadline.minute"
                  :options="minuteOptionValues"
                  label="Minute"
                  prop="settings_shipping_timer.deadline.minute"
                  name="settings_shipping_timer.deadline.minute"
                  type="select"
                  labelKey="label"
                  valueKey="value"
                  class="w-full md:w-1/2 px-2"
                />
              </div>
              <p class="text-xs opacity-75 my-1">
                Specify the cutoff time that orders must be placed to get the best shipping.
              </p>
            </div>
            <div class="w-full">
              <label v-if="settings.settings_shipping_timer.label" class="font-medium text-sm">
                {{ settings.settings_shipping_timer.label }}
              </label>
              <p v-if="settings.settings_shipping_timer.description" class="text-xs opacity-75 my-1">
                {{ settings.settings_shipping_timer.description }}
              </p>
              <div class="flex flex-col space-y-1 w-full mt-1">
                <div
                  v-for="index in Object.keys(form.settings_shipping_timer.value.days)"
                  :key="index"
                  class="flex flex-wrap items-center w-row-offset-sm -mx-2"
                >
                  <div class="w-full md:w-1/2 px-2">
                    Orders placed <strong>{{ weekValues[index].label }}</strong> will be delivered by...
                  </div>
                  <div class="w-full md:w-1/2 px-2">
                    <vue-input-group
                      v-model="form.settings_shipping_timer.value.days[index].next_day"
                      :options="weekValues"
                      :prop="`settings_shipping_timer.days.${index}.next_day`"
                      :name="`settings_shipping_timer.days.${index}.next_day`"
                      :disable-on-null="false"
                      type="select"
                      labelKey="label"
                      valueKey="value"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div :class="sectionClass">
            <vue-input-group
              v-model="form.settings_per_build_charge.value.amount"
              :label="settings.settings_per_build_charge.label"
              :description="settings.settings_per_build_charge.description"
              :prepend="$route.params.localeFull.currencySymbol"
              prop="settings_per_build_charge"
              name="settings_per_build_charge"
              type="number"
              step="0.01"
              min="0"
            />
          </div>
          <div class="flex justify-end">
            <vue-button
              :disabled="loading"
              success
              @click="saveData"
            >
              Update
            </vue-button>
          </div>
        </div>
      </vue-form>
      <vue-dialog :dialog.sync="dialog.show">
        <template #title>
          {{ dialog.title }}
        </template>
        {{ dialog.message }}
      </vue-dialog>
    </template>
  </NewModelLayout>
</template>
<script>
import { keyBy, mapValues, map, isEmpty } from 'lodash'
import NewModelLayout from '@/components/layouts/NewModelLayout'
import LocaleSwitcher from '@/components/LocaleSwitcher'
import UsesLocales from '@/mixins/UsesLocales'

export default {
  name: 'Settings',

  components: {
    NewModelLayout,
    LocaleSwitcher
  },

  mixins: [
    UsesLocales
  ],

  data () {
    return {
      loading: false,
      dialog: {
        show: false,
        title: '',
        content: ''
      },
      editing: false,
      error: [],
      values: [],
      form: {},
      sectionClass: 'bg-white shadow rounded-sm px-6 py-4',
      settings: {
        settings_info_buckets: {
          key: 'settings_info_buckets',
          type: 'setting',
          label: 'Default Info Buckets',
          default: () => {
            return {
              bucketsToShow: 3,
              buckets: [
                {
                  title: 'Bucket #1',
                  content: 'Example content goes here.',
                  icon: 'delivery',
                  icon_alt: 'Delivery'
                },
                {
                  title: 'Bucket #2',
                  content: 'Example content goes here.',
                  icon: 'warranty',
                  icon_alt: 'Warranty'
                },
                {
                  title: 'Bucket #3',
                  content: 'Example content goes here.',
                  icon: 'support',
                  icon_alt: 'Support'
                }
              ]
            }
          }
        },
        settings_footer_text: {
          key: 'settings_footer_text',
          type: 'setting',
          label: 'Footer Text Blurb',
          description: 'Write some text to display underneath the site icon in the footer.',
          default: () => {
            return {
              content: ''
            }
          }
        },
        settings_per_build_charge: {
          key: 'settings_per_build_charge',
          type: 'setting',
          label: 'Per-Build Checkout Charge',
          description: 'Specify an extra amount added per-build at the checkout, including pre-builts.',
          default: () => {
            return {
              amount: '0.00'
            }
          }
        },
        settings_shipping_cutoff_time: {
          key: 'settings_shipping_cutoff_time',
          type: 'setting',
          label: '',
          description: '',
          default: () => {
            return {
              hour: '14',
              minute: '0'
            }
          }
        },
        settings_shipping_timer: {
          key: 'settings_shipping_timer',
          type: 'setting',
          label: 'Shipping Timer',
          description: 'Specify settings for the shipping countdown timer',
          default: () => {
            return {
              enabled: true,
              deadline: {
                hour: '14',
                minute: '0'
              },
              days: [
                { next_day: 2 },
                { next_day: 3 },
                { next_day: 4 },
                { next_day: 5 },
                { next_day: 0 },
                { next_day: 1 },
                { next_day: 2 }
              ]
            }
          }
        },
        settings_shipping_countries: {
          key: 'settings_shipping_countries',
          type: 'setting',
          label: 'Shipping Countries and Prices',
          description: '',
          default: () => {
            return {
              countries: [
                {
                  code: 'GB',
                  base_amount: 0,
                  delivery_time: 1
                }
              ]
            }
          }
        },
        settings_social_media_links: {
          key: 'settings_social_media_links',
          type: 'setting',
          label: 'Social Media Links',
          description: 'Add links to different social media platforms with Font Awesome icons.',
          default: () => {
            return []
          }
        },
        settings_performance_minimum: {
          key: 'settings_performance_minimum',
          type: 'setting',
          label: 'Minimum Performance Value',
          description: "Specify the minimum FPS value that is permitted before a game is considered 'Unplayable'.",
          default: () => {
            return {
              value: 15
            }
          }
        },
        settings_performance_levels: {
          key: 'settings_performance_levels',
          type: 'setting',
          label: 'Performance Value Colours',
          description: 'Specify the colours and FPS value requirements for game boxes displaying performance values.',
          default: () => {
            return [
              {
                min: '30',
                max: '60',
                color: '#ff0000'
              },
              {
                min: '60',
                max: '120',
                color: '#f0ff00'
              },
              {
                min: '120',
                max: '',
                color: '#00ff00'
              }
            ]
          }
        }
      }
    }
  },

  computed: {
    hourOptionValues () {
      return map(Array.from(Array(24).keys()), (value) => {
        return {
          label: value.toString().padStart(2, '0'),
          value: '' + value
        }
      })
    },

    minuteOptionValues () {
      return map(Array.from(Array(60).keys()), (value) => {
        return {
          label: value.toString().padStart(2, '0'),
          value: '' + value
        }
      })
    },

    weekValues () {
      return [
        {
          label: 'Sunday',
          value: 0
        },
        {
          label: 'Monday',
          value: 1
        },
        {
          label: 'Tuesday',
          value: 2
        },
        {
          label: 'Wednesday',
          value: 3
        },
        {
          label: 'Thursday',
          value: 4
        },
        {
          label: 'Friday',
          value: 5
        },
        {
          label: 'Saturday',
          value: 6
        }
      ]
    },

    countryOptions () {
      return [
        {
          value: 'GB',
          label: 'United Kingdom'
        },
        {
          value: 'AT',
          label: 'Austria'
        },
        {
          value: 'BE',
          label: 'Belgium'
        },
        {
          value: 'DK',
          label: 'Denmark'
        },
        {
          value: 'FI',
          label: 'Finland'
        },
        {
          value: 'FR',
          label: 'France'
        },
        {
          value: 'DE',
          label: 'Germany'
        },
        {
          value: 'IT',
          label: 'Italy'
        },
        {
          value: 'IE',
          label: 'Ireland'
        },
        {
          value: 'NL',
          label: 'The Netherlands'
        },
        {
          value: 'PR',
          label: 'Portugal'
        },
        {
          value: 'ES',
          label: 'Spain'
        }
      ]
    }
  },

  mounted () {
    this.getData()
  },

  methods: {
    getData () {
      this.loading = true
      this.$api.get('metadata', {
        params: {
          locale: this.$route.params.locale,
          currency: this.$route.params.currency,
          whereIn: Object.keys(this.settings),
          type: 'setting'
        }
      })
        .then((res) => {
          this.form = keyBy(res.data, 'key')
          this.initForm()
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    initForm () {
      this.form = mapValues(this.settings, (setting) => {
        let value = this.form?.[setting.key]?.locale_value?.[this.currentLocale.iso]?.value || this.form?.[setting.key]?.value || null

        if (isEmpty(value)) {
          value = setting?.default ? setting.default() : null
        }

        return {
          id: this.form?.[setting.key]?.id || null,
          key: setting.key,
          type: setting?.type || 'setting',
          value
        }
      })
    },

    saveData () {
      this.loading = true
      this.$api.post('metadata/multiple', {
        locale: this.$route.params.locale,
        values: this.form
      })
        .then((res) => {
          this.dialog = {
            show: true,
            title: 'Settings updated successfully!'
          }
        })
        .catch((err) => {
          this.$store.commit('error/addError', err)
        })
        .finally(() => {
          this.loading = false
        })
    },

    addPerformanceLevel () {
      this.form.settings_performance_levels.value.push({
        min: '0',
        max: '0',
        color: '#222222'
      })
    },

    removePerformanceLevel (index) {
      this.form.settings_performance_levels.value.splice(index, 1)
    },

    addCountryShipping () {
      this.form.settings_shipping_countries.value.countries.push({
        code: 'GB',
        base_amount: 10,
        delivery_time: 1
      })
    },

    removeCountryShipping (index) {
      this.form.settings_shipping_countries.value.countries.splice(index, 1)
    },

    addSocialLink () {
      this.form.settings_social_media_links.value.push({
        label: 'Facebook',
        url: 'https://example.com/',
        icon: 'facebook-f'
      })
    },

    removeSocialLink (index) {
      this.form.settings_social_media_links.value.splice(index, 1)
    }
  }
}
</script>
